
import {
    Vue,
    Component,
    PropSync,
    ModelSync,
    Prop,
    Ref
} from "vue-property-decorator";
import { mapMutations, mapGetters } from "vuex";

import {
    FilterFormInterface,
    FormActions,
    ResponseDataStringArray as rdsa,
    ResponseDataString as rds
} from "@/interfaces";
import * as types from "@/store/types";

@Component({
    components: {
        CalendarInput: () => import("@/components/layout/CalendarInput.vue")
    },
    methods: {
        ...mapMutations({
            setFilterLoading: types.MUTATE_APP_FILTER_LOADING,
            setInfoMessage: types.MUTATE_APP_INFO_MESSAGE,
            setEmpresa: types.MUTATE_EMPRESA
        })
    },
    computed: {
        ...mapGetters({
            userIdentifier: types.USER_IDENTIFIER
        })
    }
})
export default class FilterForm extends Vue {
    @ModelSync("filterForm", "change", { type: Object })
    readonly formValues!: FilterFormInterface;

    @Ref("form") readonly formActions!: FormActions;

    @PropSync("filterFormPanelShow", { type: Boolean || null })
    syncFilterFormPanelShow!: null;

    setFilterLoading!: (state: boolean) => void;
    setInfoMessage!: (state: { shown: boolean; text: string | null }) => void;
    setEmpresa!: (state: number) => void;

    page = this.$route.name;
    path = this.$route.path;
    userIdentifier?: number;
    companies: string[] = [];
    boxes: string[] = [];
    clients: string[] = [];
    installations: string[] = [];
    analyzers: string[] = [];
    elements: string[] = [];
    periodType: string[] = ["Diario", "Semanal", "Mensual", "Anual"];
    advancedOptions: boolean | null = false;
    start = "";
    end = "";
    first = false;

    rules = {
        required: value => !!value || "Requerido",
        counter: value => value.length <= 3 || "Maximo 3 dígitos",
        days: value => !!Number(value) || "No es un número"
    };

    formRules = {
        window: [
            val =>
                /[0-9][d]{1}$|[0-9][h]{1}$|[0-9][m]{1}$|[0-9][s]{1}$/.test(
                    val
                ) || val == null
        ]
    };

    loading = false;

    mounted() {
        // if (this.$route.name == "Consumos" || this.$route.name == "Avanzado") {
        //     this.first = true;
        //     this.formValues.periodType = "Ultimos 30 dias";
        // }
        this.formValues.installation = "";
        this.formValues.comparative = false;
        this.formValues.days = "5";
        this.formValues.periodType = "Diario";
        this.fetchDatesBound();
        this.fetchUser();
    }

    /**
     * Methods
     */

    setFilter() {
        this.$emit("on-filter-updated");
    }

    async fetchUser() {
        if (Vue.prototype.$user.get().role == "client") {
            this.setFilterLoading(true);
            try {
                const userClientResponse = await this.$api.SQLuserClient<rdsa>(
                    this.userIdentifier
                );
                if (userClientResponse.ok) {
                    this.setEmpresa(
                        Number(userClientResponse.data[0].split(" - ")[0])
                    );
                    this.formValues.company = userClientResponse.data[0];
                    this.formValues.client = userClientResponse.data[1];
                    this.advancedOptions = true;
                    this.fetchInstallation();
                }
            } catch (error) {
                if (error instanceof Error) {
                    this.showError(error.message);
                }
            } finally {
                this.setFilterLoading(false);
            }
        } else if (Vue.prototype.$user.get().role == "admin") {
            this.fetchCompany();
        } else {
            console.log("Role undefined");
        }
    }

    async fetchCompany() {
        this.setFilterLoading(true);
        try {
            const companyResponse = await this.$api.SQLcompany<rdsa>();
            if (companyResponse.ok) {
                this.formValues.periodType = "Mensual";
                this.companies = companyResponse.data;
                this.formValues.company = this.companies[0];
                this.fetchClient();
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            this.setFilterLoading(false);
        }
    }

    async fetchClient() {
        this.setFilterLoading(true);
        try {
            const clientResponse = await this.$api.SQLclient<rdsa>(
                this.formValues.company.split(" - ")[0]
            );
            if (clientResponse.ok) {
                this.clients = clientResponse.data;
                this.formValues.client = this.clients[0];
                this.fetchInstallation();
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            this.setFilterLoading(false);
        }
    }

    async fetchInstallation() {
        this.setFilterLoading(true);
        try {
            const installResponse = await this.$api.SQLinstallation<rdsa>(
                this.formValues.client.split(" - ")[0]
            );
            if (installResponse.ok) {
                this.installations = installResponse.data;
                this.formValues.installation = this.installations[0];
                if (this.path != "/consdia" && this.path != "/energy") {
                    this.fetchModbus();
                }
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            this.setFilterLoading(false);
        }
    }

    async fetchModbus() {
        if (this.path != "/consdia" && this.path != "/energy") {
            this.setFilterLoading(true);
            try {
                const modbusResponse = await this.$api.SQLmodbus<rdsa>(
                    this.formValues.installation.split(" - ")[0]
                );
                if (modbusResponse.ok) {
                    this.elements = modbusResponse.data;
                    if (this.page == "Avanzado") {
                        this.formValues.modbus = this.elements[0];
                        this.fecthBox();
                    }
                }
            } catch (error) {
                if (error instanceof Error) {
                    this.showError(error.message);
                }
            } finally {
                this.setFilterLoading(false);
            }
        }
    }

    async fecthBox() {
        this.setFilterLoading(true);
        try {
            const boxesResponse = await this.$api.SQLbox<rds>(
                this.formValues.installation.split(" - ")[0],
                this.formValues.modbus.split(" - ")[0]
            );
            if (boxesResponse.ok) {
                this.formValues.box = boxesResponse.data;
                this.fetchLments();
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            this.setFilterLoading(false);
        }
    }

    async fetchLments() {
        this.setFilterLoading(true);
        try {
            const elementResponse = await this.$api.SQLelement<rds>(
                this.formValues.installation.split(" - ")[0],
                this.formValues.modbus.split(" - ")[0]
            );
            if (elementResponse.ok) {
                this.formValues.element = elementResponse.data;
                this.fecthAnalyzer();
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            this.setFilterLoading(false);
        }
    }

    async fecthAnalyzer() {
        this.setFilterLoading(true);
        try {
            const analyzerResponse = await this.$api.SQLanalyzer<rds>(
                this.formValues.installation.split(" - ")[0],
                this.formValues.modbus.split(" - ")[0]
            );
            if (analyzerResponse.ok) {
                this.formValues.analyzer = analyzerResponse.data
                    ? analyzerResponse.data
                    : "";
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            this.setFilterLoading(false);
        }
    }

    fetchDatesBound() {
        this.setFilterLoading(true);
        try {
            this.formValues.start = new Date().toISOString().split("T")[0];
            this.start = this.formValues.start;

            this.formValues.end = new Date(
                new Date(this.formValues.start).setDate(
                    new Date(this.formValues.start).getDate() + 1
                )
            )
                .toISOString()
                .split("T")[0];
            this.end = this.formValues.end;
            this.datesUpdates();
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            this.setFilterLoading(false);
        }
    }

    async datesUpdates() {
        if (this.start || this.end) {
            if (!this.formValues.comparative) {
                try {
                    const datesResponse = await this.$api.datesUpdates<rdsa>(
                        this.start,
                        this.formValues.periodType
                    );
                    if (datesResponse.ok) {
                        this.formValues.start = datesResponse.data[0];
                        this.formValues.end = datesResponse.data[1];
                    }
                } catch (error) {
                    if (error instanceof Error) {
                        this.showError(error.message);
                    }
                }
                switch (this.formValues.periodType) {
                    case "Diario":
                        if (this.page == "Avanzado") {
                            this.formValues.window = undefined;
                            this.formValues.aggregation = undefined;
                        } else {
                            this.formValues.window = "15m";
                            this.formValues.aggregation = "mean";
                        }
                        break;
                    case "Semanal":
                        if (this.page == "Avanzado") {
                            this.formValues.window = "15m";
                            this.formValues.aggregation = "mean";
                        } else {
                            this.formValues.window = "1h";
                            this.formValues.aggregation = "mean";
                        }
                        break;
                    case "Mensual":
                        if (this.page == "Avanzado") {
                            this.formValues.window = "1h";
                            this.formValues.aggregation = "mean";
                        } else {
                            this.formValues.window = "6h";
                            this.formValues.aggregation = "mean";
                        }
                        break;
                    case "Anual":
                        if (this.page == "Avanzado") {
                            this.formValues.window = "1d";
                            this.formValues.aggregation = "mean";
                        } else {
                            this.formValues.window = "1d";
                            this.formValues.aggregation = "mean";
                        }
                        break;
                    default:
                        undefined;
                }
            } else if (this.formValues.comparative) {
                this.formValues.start = this.start;
                this.formValues.end = this.end;
                const aux = Number(this.formValues.days);
                if (aux < 7) {
                    this.formValues.window = undefined;
                    this.formValues.window = undefined;
                    this.formValues.aggregation = undefined;
                } else if (aux >= 7 && aux < 30) {
                    this.formValues.window = "15m";
                    this.formValues.aggregation = "mean";
                } else if (aux >= 30 && aux < 365) {
                    this.formValues.window = "1h";
                    this.formValues.aggregation = "mean";
                } else {
                    this.formValues.window = "1d";
                    this.formValues.aggregation = "mean";
                }
            }
        }
    }

    async downloadCSV() {
        // window.location.href =
        //     window.location.origin +
        //     `/api/common/download-csv?box=${this.formValues.box}&analyzer=${this.formValues.analyzer}&element=${this.formValues.element}&start=${this.formValues.start}&end=${this.formValues.end}`;
        try {
            const csvResponse = await this.$api.downloadCSV<rds>(
                this.formValues.box,
                this.formValues.analyzer,
                this.formValues.element,
                this.formValues.start,
                this.formValues.end
            );

            const anchor = document.createElement("a");
            anchor.href =
                "data:text/csv;charset=utf-8," +
                encodeURIComponent(String(csvResponse));
            anchor.target = "_blank";
            anchor.download =
                this.formValues.client.split(" - ")[1] +
                "_Avanzado_" +
                this.formValues.start +
                "_" +
                this.formValues.end +
                ".csv";
            anchor.click();
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    showError(error: string) {
        this.setInfoMessage({ shown: true, text: error });
    }

    resetForm0() {
        this.formValues.client = "";
        this.formValues.installation = "";
        this.formValues.modbus = "";
        this.formValues.analyzer = "";
        this.formValues.element = "";
        // this.formValues.start = undefined;
        // this.formValues.end = undefined;
        // this.formValues.window = undefined;
        // this.formValues.aggregation = undefined;
    }

    resetForm1() {
        this.formValues.installation = "";
        this.formValues.modbus = "";
        this.formValues.analyzer = "";
        this.formValues.element = "";
        // this.formValues.start = undefined;
        // this.formValues.end = undefined;
        // this.formValues.window = undefined;
        // this.formValues.aggregation = undefined;
    }

    resetForm2() {
        this.formValues.modbus = "";
        this.formValues.analyzer = "";
        this.formValues.element = "";
        // this.formValues.start = undefined;
        // this.formValues.end = undefined;
        // this.formValues.window = undefined;
        // this.formValues.aggregation = undefined;
    }

    onFilterUpdated() {
        if (this.formActions.validate()) {
            this.$emit("on-filter-updated");
        }
    }
}
